// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleRight     as fasFaAngleRight     } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faCalendar       as fasFaCalendar       } from "@fortawesome/pro-solid-svg-icons/faCalendar";
import { faClipboard      as fasFaClipboard      } from "@fortawesome/pro-solid-svg-icons/faClipboard";
import { faComment        as fasFaComment        } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder         as fasFaFolder         } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faNewspaper      as fasFaNewspaper      } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faShoppingCart   as fasFaShoppingCart   } from "@fortawesome/pro-solid-svg-icons/faShoppingCart";
import { faTag            as fasFaTag            } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTimes          as fasFaTimes          } from "@fortawesome/pro-solid-svg-icons/faTimes";

import { faAngleDown      as farFaAngleDown      } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleLeft      as farFaAngleLeft      } from "@fortawesome/pro-regular-svg-icons/faAngleLeft";
import { faAngleRight     as farFaAngleRight     } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faBars           as farFaBars           } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faClock          as farFaClock          } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faSearch         as farFaSearch         } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faQuestionCircle as farFaQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faUserCircle     as farFaUserCircle     } from "@fortawesome/pro-regular-svg-icons/faUserCircle";

import { faFacebookF      as fabFaFacebookF      } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram      as fabFaInstagram      } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter        as fabFaTwitter        } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube        as fabFaYoutube        } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add solid icons
 */
library.add(fasFaAngleRight, fasFaCalendar, fasFaClipboard, fasFaComment, fasFaFolder, fasFaNewspaper, fasFaShoppingCart, fasFaTag, fasFaTimes);

/**
 * Add regular icons
 */
library.add(farFaAngleDown, farFaAngleLeft, farFaAngleRight, farFaBars, farFaClock, farFaSearch, farFaQuestionCircle, farFaUserCircle);

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaInstagram, fabFaTwitter, fabFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
